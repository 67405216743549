import gql from 'graphql-tag';
// import { Timesheet } from '../timesheets/queries';

const GET_TIMESHEET_BY_ID = gql`
  fragment WeekdayReport on Task {
    _id
    date
    hours
    comment
    project {
      _id
      name
      clientName
    }
    deliverable {
      _id
      milestoneName
      deliverableName
      ignoreHoursInTotal
    }
  }
  query getTimesheetById($timesheetId: String!, $projectId: String) {
    reports {
      timesheet(timesheetId: $timesheetId, projectId: $projectId) {
        _id
        user {
          _id
          firstName
          lastName
        }
        status
        totalHours
        endDate
        approvalsRequired {
          status
          approvedOn
          approvedByUser {
            _id
            firstName
            lastName
          }
        }
        week {
          mon {
            ...WeekdayReport
          }
          tue {
            ...WeekdayReport
          }
          wed {
            ...WeekdayReport
          }
          thu {
            ...WeekdayReport
          }
          fri {
            ...WeekdayReport
          }
          sat {
            ...WeekdayReport
          }
          sun {
            ...WeekdayReport
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line
export { GET_TIMESHEET_BY_ID };
