import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Drawer from '../../common/components/Drawer';
import Icon from '../../common/components/Icon';
import Tooltip from '../../common/components/Tooltip';
import TimesheetGridRowCommentUpdate from './TimesheetGridRowCommentUpdate';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledIcon = styled(({ containsData, children, ...rest }) => (
  <Icon {...rest} />
))`
  font-size: 2em;
  color: #e2e2e2;
  ${props => props.containsData && 'color: #acabab'};
  &:hover {
    cursor: pointer;
  }
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const Row = styled.tr`
  border-bottom: 1px solid #f1f1f1;
  &:last-child {
    border-bottom: none;
  }
`;

const Col = styled.td`
  padding: 1em;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  &:first-child {
    padding-left: 0;
  }
`;

const Day = styled.div`
  font-weight: bold;
`;

const TaskTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

const ProjectName = styled.div`
  font-weight: bold;
`;

const TaskName = styled.div``;

const NoComment = styled.span`
  font-weight: 300;
  color: #bdbdbd;
`;

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const hasComments = lineItem => daysOfWeek
  .map(day => {
    const dayTask = lineItem[`${day.toLowerCase()}Task`];
    return dayTask;
  })
  .some(dayTask => {
    if (dayTask && dayTask.comment) {
      return true;
    }
    return false;
  });
const hasHours = lineItem => daysOfWeek
  .map(day => {
    const dayTask = lineItem[`${day.toLowerCase()}Task`];
    return dayTask;
  })
  .some(dayTask => {
    if (dayTask && dayTask.hours >= 0) {
      return true;
    }
    return false;
  });

class TimesheetGridRowComment extends React.Component {
  state = {
    isUpdating: false,
    visible: false,
  };

  handleClose = () => {
    this.setState({ visible: false });
  };

  handleClick = () => {
    this.setState({ visible: true });
  };

  render() {
    const { lineItem, isLocked , user } = this.props;
    const { visible, isUpdating } = this.state;
    const { project, deliverable } = lineItem;
    return (
      <div>
        {hasHours(lineItem) && (
          <Wrapper>
            <Tooltip title="Comments">
              <StyledIcon
                onClick={this.handleClick}
                containsData={hasComments(lineItem)}
                type={isUpdating ? 'loading' : 'message'}
              />
            </Tooltip>
          </Wrapper>
        )}
        <Drawer
          title="Comments"
          placement="left"
          width={400}
          onClose={this.handleClose}
          visible={visible}
        >
          <TaskTitleContainer>
            <ProjectName>
              {`${project.client.shortName} / ${project.name}`}
            </ProjectName>
            <TaskName>
              {`${deliverable.milestoneName} / ${deliverable.deliverableName}`}
            </TaskName>
          </TaskTitleContainer>
          <Table>
            <tbody>
              {daysOfWeek.map(day => {
                // get the <day>Task object out of lineItem object
                const dayTask = lineItem[`${day.toLowerCase()}Task`];
                // show comment field if dayTask exists
                if (dayTask) {
                  const { comment, date } = dayTask;
                  return (
                    <Row key={day}>
                      <Col width="70px">
                        <Day>{day}</Day>
                        <div>{moment(date).format('M/D')}</div>
                      </Col>
                      <Col>
                        {isLocked ? (
                          comment || <NoComment>No Comment</NoComment>
                        ) : (
                          <TimesheetGridRowCommentUpdate
                            comment={comment}
                            taskId={dayTask._id}
                            user = {user}
                            // this is for reteching timesheet after update
                            endDate={moment(date)
                              .endOf('isoWeek')
                              .format('YYYYMMDD')}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
        </Drawer>
      </div>
    );
  }
}

export default TimesheetGridRowComment;
