import { useState } from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import { RESET_USER_PASSWORD } from '../../../admin/mutations';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ResetUserPasswordForm = ({ onComplete, client, userId, userEmail }) => {
  const [passwordError, setPasswordError] = useState('');
  const [hasError, setHasError] = useState(false);

  return (
    <Formik
      initialValues={{
        password: '',
        email: userEmail,
      }}
      validate={values => {
        const errors = {};
        if (!values.password) {
          errors.password = 'A new password is required.';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { email, password } = values;
        return client
          .mutate({
            mutation: RESET_USER_PASSWORD,
            variables: {
              id: userId,
              email,
              password,
            },
          })
          .then(() => {
            setSubmitting(false);
            onComplete();
            values.password = '';
          })
          .catch(err => {
            console.log('Error Message: ', err.message);
            setHasError(true);
            setPasswordError('Oops something went wrong');
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} {...formItemLayout}>
          <Form.Item label="Email" hasFeedback required>
            <Input
              type="email"
              name="email"
              placeholder={userEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              value={userEmail}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            hasFeedback
            validateStatus={errors.password && touched.password && 'error'}
            help={errors.password && touched.password && errors.password}
          >
            <Input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {hasError && <p>{passwordError}</p>}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          />
          <Button
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Reset User Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withApollo(ResetUserPasswordForm);
