import React from 'react';
import { Query } from 'react-apollo';
import { useGlobal } from 'reactn';
import moment from 'moment';
import { get } from 'lodash';
import TimesheetApprovedList from './TimesheetApprovedList';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import { GET_APPROVED_TIMESHEETS } from '../queries';

const TimesheetApprovedListContainer = () => {
  const [user] = useGlobal('user');
  const [endDate, setEndDate] = useGlobal('endDate');
  return (
    <Query query={GET_APPROVED_TIMESHEETS} variables={{ endDate }}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        return (
          <Card
            border
            padded={false}
            withShadow
            floating
            title="Approved Timesheets"
            actionComponent={
              <WeekPicker
                endDate={endDate}
                onDateChange={endDate => {
                  const endDateString = moment(endDate).format('YYYYMMDD');
                  setEndDate(endDateString);
                }}
              />
            }
          >
            <TimesheetApprovedList
              user={user}
              timesheets={get(data, 'projectManagement.approvedTimesheets')}
              loading={loading}
            />
          </Card>
        );
      }}
    </Query>
  );
};

export default TimesheetApprovedListContainer;
