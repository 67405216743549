import React from 'reactn';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import Icon from 'antd/lib/icon';
import TimesheetCreate from './TimesheetCreate';
import TimesheetGrid from './TimesheetGrid';
import TimesheetTimeline from './TimesheetTimeline';
import Tag from '../../common/components/Tag';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';
import { getColorByStatus } from '../../common';
import { GET_USER_TIMESHEET } from '../queries';
import { SUBMIT_TIMESHEET, RECALL_TIMESHEET } from '../mutations';
import { Alert } from 'antd';
import TimesheetApprovers from '../../project-manager/components/TimesheetApprovers';

const Wrapper = styled.div`
  background-color: white;
  padding: 1em;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TimesheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const WeekOf = styled.div`
  font-size: 1.7em;
  font-weight: 300;
  width: 100%;
  margin-right: 1em;
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-right: 10px;
`;

const HoursValue = styled.span`
  font-size: 1.7em;
  ${props => props.highlightColor && `color: ${props.highlightColor}`};
`;

const SubLabel = styled.span`
  color: #bdbdbd;
  font-weight: 300;
`;
const totalHours = lineItems => {
  let hours = 0;
  if (lineItems)
    lineItems.forEach(item => {
      hours += item.monTask ? item.monTask.hours : 0;
      hours += item.tueTask ? item.tueTask.hours : 0;
      hours += item.wedTask ? item.wedTask.hours : 0;
      hours += item.thuTask ? item.thuTask.hours : 0;
      hours += item.friTask ? item.friTask.hours : 0;
      hours += item.satTask ? item.satTask.hours : 0;
      hours += item.sunTask ? item.sunTask.hours : 0;
    });
  return hours;
};
// let threshold = '';
class Timesheet extends React.Component {
  state = {
    submitLoading: false,
    recallLoading: false,
    totalHoursError: '',
    user: this.global.user,
    threshold: '',
  };

  submitTimesheet = () => {
    const { employeeType } = this.state.user;
    const { timesheetThreshold } = this.props.threshold;
    const maxHours = timesheetThreshold?.max || 80;
    const minHours = timesheetThreshold?.min || 0;
    const totalIncHours = this.props.timesheet.lineItems.length
      ? totalHours(this.props.timesheet.lineItems)
      : 0;

    if (totalIncHours < minHours && employeeType === 'W-2') {
      this.setState({
        totalHoursError: `Please submit all relevant hours including PTO, Company Paid Time Off, etc. Must add upto ${minHours} hours`,
      });
    } else if (totalIncHours > maxHours) {
      this.setState({
        totalHoursError: `Total logged hours cannot be more than ${maxHours} in a week. Please contact your manager if you need to submit more than ${maxHours} hours`,
      });
    } else {
      this.setState({ submitLoading: true });
      this.setState({ totalHoursError: '' });
      this.props.client
        .mutate({
          mutation: SUBMIT_TIMESHEET,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_USER_TIMESHEET,
              variables: {
                endDate: this.props.endDate,
              },
            },
          ],
          variables: {
            timesheetId: this.props.timesheet._id,
          },
        })
        // componented will be unmounted so no need to call setState
        .then(() => this.setState({ submitLoading: false }));
    }
  };

  recallTimesheet = () => {
    this.setState({ recallLoading: true });
    this.props.client
      .mutate({
        mutation: RECALL_TIMESHEET,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate: this.props.endDate,
            },
          },
        ],
        variables: {
          timesheetId: this.props.timesheet._id,
        },
      })
      // componented will be unmounted so no need to call setState
      .then(() => this.setState({ recallLoading: false }));
  };

  renderTotalHours = (hours, lineItems) => {
    let highlightColor;
    const total = totalHours(lineItems);
    if (total > 40) {
      highlightColor = 'red';
    }
    // if (hours > 50) {
    //   highlightColor = 'red';
    // }
    // this.updateWarning();
    return (
      <>
        <div style={{ borderRight: '1px solid #ededed' }}>
          <HoursWrapper>
            <HoursValue highlightColor={highlightColor}>{hours}</HoursValue>
            <SubLabel> Working hours</SubLabel>
          </HoursWrapper>
        </div>

        <HoursWrapper>
          <HoursValue highlightColor={highlightColor}>{total}</HoursValue>
          <SubLabel> Total hours</SubLabel>
        </HoursWrapper>
      </>
    );
  };

  updateWarning(lineItems) {
    let message;
    if (totalHours(lineItems) > 40) {
      if (this.state.totalHoursError) {
        this.setState({ totalHoursError: '' });
      }
      message = 'Warning : Timesheet contains more than 40 hours';
    }
    return message ? (
      <Alert
        style={{ width: '100%', height: '50%', padding: '8px' }}
        description={message}
        type="warning"
      />
    ) : (
      <></>
    );
  }
  popconfirmMessage = lineItems => {
    let message = (
      <>
        <Icon style={{ padding: '5px', color: '#e1ca29' }} type="exclamation-circle" />
        <span style={{ padding: '5px' }}>Submit timesheet for approval ?</span>
      </>
    );
    if (totalHours(lineItems) < 35) {
      message = (
        <>
          {' '}
          <Alert
            style={{ width: '100%', height: '100%', padding: '8px', marginBottom: '5px' }}
            message="Warning : Timesheet has less than 35 hours"
            description=" Make sure you have added all the PTO's , Company Vacations etc."
            type="warning"
          />
          <Icon style={{ padding: '5px', color: '#e1ca29' }} type="exclamation-circle" />
          <span style={{ padding: '5px' }}>
            Are you sure you want to submit timesheet for approval ?
          </span>
        </>
      );
    }
    return message;
  };

  render() {
    // this.updateWarning()
    const { timesheet, endDate, readOnly } = this.props;

    if (!timesheet) return <TimesheetCreate endDate={endDate} />;
    const { isLocked, timeline, documentUrl } = timesheet;
    return (
      <Wrapper>
        <TimesheetHeader>
          <WeekOf>
            {`${moment(timesheet.startDate).format('MMMM Do')} - ${moment(timesheet.endDate).format(
              'MMMM Do',
            )}`}
          </WeekOf>
          {documentUrl && (
            <Button href={documentUrl} target="_blank" icon="cloud-download">
              Download
            </Button>
          )}
          {this.state.totalHoursError ? (
            <Alert
              style={{ width: '100%', height: '50%', padding: '8px' }}
              description={this.state.totalHoursError}
              type="error"
            />
          ) : (
            this.updateWarning(timesheet.lineItems)
          )}
          {this.renderTotalHours(timesheet.totalHours, timesheet.lineItems)}
        </TimesheetHeader>
        <div className="flex">
          {timeline && timeline.length > 0 && (
            <div style={{ margin: '5px' }}>
              <TimesheetTimeline timeline={timeline} status={timesheet.status} />
            </div>
          )}
          <div style={{ margin: '5px' }}>
            <Tag color={getColorByStatus(timesheet.status)}>{timesheet.status}</Tag>
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <div className="text-right text-lg font-medium mb-2 -mt-8">Approvers</div>
          <div className="flex justify-end gap-2">
            <TimesheetApprovers approvalsRequired={timesheet.approvalsRequired} />
          </div>
        </div>
        <TimesheetGrid
          endDate={timesheet.endDate}
          timesheetId={timesheet._id}
          lineItems={timesheet.lineItems}
          isLocked={isLocked || readOnly || this.state.submitLoading}
        />
        <SubmitButtonContainer>
          {!readOnly && !isLocked && timesheet.lineItems.length > 0 && (
            <>
              {['Not Submitted', 'Declined', 'Cancelled'].includes(timesheet.status) && (
                <Popconfirm
                  title={this.popconfirmMessage(timesheet.lineItems)}
                  onConfirm={this.submitTimesheet}
                  placement="left"
                  okText="Yes"
                  cancelText="No"
                  icon={false}
                >
                  <Button
                    loading={this.state.submitLoading || isLocked}
                    disabled={this.global.loading}
                    type="primary"
                    icon="upload"
                  >
                    Submit
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
          {!readOnly && timesheet.status === 'Submitted-Pending' && (
            <Button loading={this.state.recallLoading} onClick={this.recallTimesheet} icon="undo">
              Recall Timesheet
            </Button>
          )}
        </SubmitButtonContainer>
      </Wrapper>
    );
  }
}

export default withApollo(Timesheet);
