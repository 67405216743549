import React from 'react';
import randomcolor from 'randomcolor';
import { Avatar, Tag, Tooltip } from 'antd';

const Profile = ({
  allowProjectManagement,
  timesheetApprover,
  imageUrl,
  firstName = '',
  lastName = '',
  title,
  email,
  noWrap = false,
  employeeType,
  firstLine,
  secondLine,
  ...rest
}) => {
  let name = `${firstName} ${lastName}`.trim();
  return (
    <div className="flex" {...rest}>
      <Avatar
        className="shadow-md border-solid border border-white"
        size={64}
        src={imageUrl}
        style={{
          backgroundColor: randomcolor({ seed: email, luminosity: 'dark' }),
        }}
      >{`${firstName?.charAt(0) + lastName?.charAt(0)}`}</Avatar>

      <div className="ml-2">
        <div className="flex">
          {<span className="mr-2">{name}</span>}
          {employeeType && (
            <Tag color={employeeType === 'W-2' ? 'blue' : 'orange'} className="ml-2">
              {employeeType}
            </Tag>
          )}
          {(['Project Manager', 'Delivery Lead', 'Engagement Manager'].includes(title) ||
            allowProjectManagement) && (
            <Tooltip title="This user can manage this project">
              <Tag>PM</Tag>
            </Tooltip>
          )}
          {timesheetApprover && (
            <Tooltip title="This user can approver project timesheets.">
              <Tag>Approver</Tag>
            </Tooltip>
          )}
        </div>
        {name.trim() === '' && <div className="whitespace-no-wrap">User has not logged in yet</div>}
        {!firstLine && title && <div className="font-light whitespace-no-wrap">{title}</div>}
        {!secondLine && email && <div className="font-light whitespace-no-wrap">{email}</div>}
        {firstLine && <div className="font-light whitespace-no-wrap">{firstLine}</div>}
        {secondLine && <div className="font-light whitespace-no-wrap">{secondLine}</div>}
      </div>
    </div>
  );
};

export default Profile;
