import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Tooltip, Statistic, Button, Avatar, Badge } from 'antd';
import Tag from '../../common/components/Tag';
import TimesheetListActions from './TimesheetListActions';
import { getColorByStatus } from '../../common';
import { CANCEL_TIMESHEET_TIMESHEET_LINEITEM } from '../../project-manager/mutations';
import { GET_TIMESHEETS_FOR_USER } from '../queries';
import TimesheetLoading from './TimesheetLoading';
import classNames from 'classnames';
import { DELETE_TIMESHEET } from '../mutations';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

const TimesheetListContainer = ({ user, startDate, endDate }) => {
  const history = useHistory();
  const [months, setMonths] = useState(null);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { loading, data, refetch } = useQuery(GET_TIMESHEETS_FOR_USER, {
    variables: { startDate, endDate },
  });

  const timesheets = get(data, 'me.timesheets');
  const [cancelTimesheet] = useMutation(CANCEL_TIMESHEET_TIMESHEET_LINEITEM);
  const [deleteTimesheet] = useMutation(DELETE_TIMESHEET);

  useEffect(() => {
    if (timesheets) {
      const startMoment = moment(startDate, 'YYYYMMDD');
      const endMoment = moment(endDate, 'YYYYMMDD');
      const numOfMonths = endMoment.diff(startMoment, 'months') + 1;
      const _months = [...new Array(numOfMonths)].map((_, index) => {
        const name = new moment(endMoment).subtract(index, 'month').format('MMMM');
        const number = new moment(endMoment).subtract(index, 'month').format('MM');
        const year = new moment(endMoment).subtract(index, 'month').format('YYYY');

        const weeks = [];
        let sunday = moment()
          .year(year)
          .month(Number(number) - 1)
          .date(1)
          .endOf('isoWeek');
        do {
          const endDate = sunday.format('YYYYMMDD');
          weeks.push({
            endDate,
            timesheet: timesheets.find(t => t.endDate === endDate),
          });
          sunday.add(7, 'days');
        } while (sunday.format('YYYYMM') === `${year}${number}`);

        return {
          name,
          number,
          year,
          weeks: weeks.sort((a, b) => b.endDate - a.endDate),
          timesheets: timesheets?.filter(t => t.endDate.startsWith(`${year}${number}`)),
        };
      });
      setMonths(_months);
      setInitialLoaded(true);
    }
  }, [timesheets, endDate, startDate]);

  if (loading && !initialLoaded) {
    return <TimesheetLoading />;
  }

  return (
    <div>
      {months &&
        months.map(({ year, timesheets: timesheetsInMonth, name, weeks }, index) => {
          return (
            <div key={index}>
              <div className="text-2xl font-medium mb-2">{`${name} ${year}`}</div>
              <div className="flex flex-wrap mb-4">
                {weeks.map(week => {
                  const currentWeek = moment().endOf('isoWeek').format('YYYYMMDD') === week.endDate;
                  if (week.timesheet) {
                    const { timesheet } = week;
                    const { _id, endDate, status, totalHours, approvalsRequired } = timesheet;
                    return (
                      <div key={_id} className="p-1 w-full md:w-1/2 lg:w-1/3">
                        <div
                          className={classNames(
                            'relative bg-white p-4 rounded-md h-64 hover:shadow-lg shadow-md transition-shadow duration-200 ',
                            {
                              'border border-solid border-gray-400': !currentWeek,
                              'border border-gray-500 border-solid': currentWeek,
                            },
                          )}
                        >
                          {currentWeek && (
                            <div className="absolute bottom-0 left-0 p-1 bg-gray-500 text-xs text-white rounded-tr-md">
                              Current Week
                            </div>
                          )}
                          <div className="flex justify-between">
                            <div>
                              <Tag color={getColorByStatus(status)}>{status}</Tag>
                            </div>
                            <TimesheetListActions
                              timesheet={timesheet}
                              cancel={() =>
                                cancelTimesheet({
                                  variables: { timesheetId: _id },
                                })
                              }
                              deleteTimesheet={() => {
                                deleteTimesheet({
                                  variables: { timesheetId: _id },
                                });
                              }}
                              refetch={refetch}
                            />
                          </div>
                          <div className="flex justify-between">
                            <div className="text-lg text-gray-800">
                              {moment(endDate, 'YYYYMMDD').format('LL')}
                            </div>
                          </div>
                          <div className="flex justify-between py-2">
                            <Statistic title="Total Hours" value={totalHours} />
                            <div>
                              <div className="">
                                {approvalsRequired?.length === 0 ? (
                                  <Statistic
                                    title="Approvals Needed"
                                    value={
                                      approvalsRequired.length === 0
                                        ? 'None'
                                        : approvalsRequired.filter(a => a.approvedBy).length
                                    }
                                    suffix={
                                      approvalsRequired.length === 0
                                        ? null
                                        : `/ ${approvalsRequired.length}`
                                    }
                                  />
                                ) : (
                                  <div>
                                    <div
                                      className="text-right"
                                      style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                    >
                                      Approvals Needed
                                    </div>
                                    <div className="flex gap-2 my-3">
                                      {timesheet.approvalsRequired?.map((approval, index) => {
                                        return (
                                          <div className="flex gap-1" key={approval._id}>
                                            {approval.approvers.map(user => {
                                              return (
                                                <Badge
                                                  key={user._id}
                                                  dot
                                                  color={
                                                    approval.status === 'Approved'
                                                      ? 'green'
                                                      : approval.status === 'Declined'
                                                      ? 'red'
                                                      : 'orange'
                                                  }
                                                  offset={[-5, 5]}
                                                >
                                                  <Tooltip
                                                    title={`${user.firstName} ${user.lastName}`}
                                                  >
                                                    <Avatar
                                                      className="shadow-md border-solid border border-white"
                                                      size={50}
                                                      src={user.imageUrl}
                                                      style={
                                                        {
                                                          // backgroundColor: randomcolor({
                                                          //   seed: user.emails[0].address,
                                                          //   luminosity: 'dark',
                                                          // }),
                                                        }
                                                      }
                                                    >{`${
                                                      user.firstName?.charAt(0) +
                                                      user.lastName?.charAt(0)
                                                    }`}</Avatar>
                                                  </Tooltip>
                                                </Badge>
                                              );
                                            })}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <Statistic
                              title="Approvals Needed"
                              value={
                                approvalsRequired.length === 0 ? 'None' : approvalsRequired.filter(a => a.approvedBy).length
                              }
                              suffix={
                                approvalsRequired.length === 0
                                  ? null
                                  : `/ ${approvalsRequired.length}`
                              }
                            /> */}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div key={week.endDate} className="p-1 w-full md:w-1/2 lg:w-1/3">
                      <div
                        className={classNames(
                          'relative p-4 rounded-md h-64 shadow-inner bg-gray-200',
                          {
                            'border border-dashed border-gray-400': !currentWeek,
                            'border border-gray-500 border-solid': currentWeek,
                          },
                        )}
                      >
                        {currentWeek && (
                          <div className="absolute bottom-0 left-0 p-1 bg-gray-500 text-xs text-white rounded-tr-md">
                            Current Week
                          </div>
                        )}
                        <div className="text-lg text-gray-800">
                          {moment(week.endDate, 'YYYYMMDD').format('LL')}
                        </div>
                        <div className="h-full flex items-center flex-col">
                          <div className="text-base text-gray-700 mb-4 mt-12">
                            No timesheet exists for this week
                          </div>
                          <Button
                            type="primary"
                            onClick={() => history.push(`/user/timesheets/${week.endDate}`)}
                          >
                            Create
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default connect(mapStateToProps)(TimesheetListContainer);
