import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import ManualTimesheetContainer from './ManualTimesheetContainer';

class ManualTimesheetCard extends React.Component {
  changeUrl = endDate => this.props.history.push(`/finance/manualTimesheets/${endDate}`);

  componentDidMount() {
    const paramsEndDate = get(this.props, 'match.params.endDate');
    // if url endDate is not specified in URL, then redirect to current weeks endDate
    if (paramsEndDate === 'create' || !paramsEndDate) {
      this.props.history.replace(`/finance/manualTimesheets/${this.props.endDate}`);
    } else if (this.props.endDate !== paramsEndDate) {
      // if url endDate does not match to redux state endDate, update state to match URL endDate
      const currentEndDate = moment(paramsEndDate).endOf('isoWeek');
      this.props.changeTimesheetDate(currentEndDate.format('YYYYMMDD'));
      this.props.history.replace(`/finance/manualTimesheets/${currentEndDate.format('YYYYMMDD')}`);
    }
  }

  render() {
    return (
      <Card
        title="Weekly Timesheet"
        padded={false}
        floating
        withShadow
        actionComponent={
          <WeekPicker
            endDate={this.props.endDate}
            onDateChange={endDate => {
              const endDateString = moment(endDate).format('YYYYMMDD');
              this.changeUrl(endDateString);
              this.props.changeTimesheetDate(endDateString);
            }}
          />
        }
      >
        <ManualTimesheetContainer endDate={this.props.endDate} user={this.props.user} />
      </Card>
    );
  }
}

export default ManualTimesheetCard;
