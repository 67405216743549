import React from 'react';
import Taxonomy from './Taxonomy';

const TaxnomoyPage = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
      <Taxonomy title="User Roles" termNameSingular="Role" termNamePlural="Roles" />
      <Taxonomy title="Sectors" termNameSingular="Sector" termNamePlural="Sectors" />
    </div>
  );
};

export default TaxnomoyPage;
