import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import Popconfirm from '../../common/components/Popconfirm';
import NewTaxonomyForm from './NewTaxonomyForm';
import EditTaxonomyForm from './EditTaxonomyForm';

const GET_TAXONOMY = gql`
  query getTaxonomy($name: String!) {
    taxonomy(name: $name) {
      _id
      name
      description
      terms {
        _id
        name
        order
      }
    }
  }
`;
const CREATE_TERM = gql`
  mutation createTerm($taxonomyName: String!, $term: String!) {
    createTerm(taxonomyName: $taxonomyName, term: $term)
  }
`;
const DELETE_TERM = gql`
  mutation deleteTerm($taxonomyId: ID!, $termId: ID!) {
    deleteTerm(taxonomyId: $taxonomyId, termId: $termId)
  }
`;
const EDIT_TERM = gql`
  mutation editTerm($taxonomyId: ID!, $termId: ID!, $term: String!) {
    editTerm(taxonomyId: $taxonomyId, termId: $termId, term: $term)
  }
`;

const TaxonomyActions = ({ onEdit, taxonomyId, termId, termNamePlural }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [deleteTerm] = useMutation(DELETE_TERM);
  return (
    <div className="w-full flex justify-end">
      <Button className="mr-2" onClick={() => onEdit({ termId })}>
        Edit
      </Button>
      <Popconfirm
        placement="topLeft"
        title="Are you sure you want to delete this term?"
        onConfirm={async () => {
          setIsRemoving(true);
          await deleteTerm({
            variables: { taxonomyId, termId },
            refetchQueries: [
              {
                query: GET_TAXONOMY,
                variables: {
                  name: termNamePlural,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="danger" loading={isRemoving} icon="close"></Button>
      </Popconfirm>
    </div>
  );
};

const Taxnomoy = ({ title, termNameSingular, termNamePlural }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { loading, data } = useQuery(GET_TAXONOMY, {
    variables: {
      name: termNamePlural,
    },
  });
  const [termEditing, setEditingTerm] = useState(null);
  const taxonomyId = data?.taxonomy?._id;
  const terms = data?.taxonomy?.terms.map(t => ({ key: t.name, ...t })) || [];

  return (
    <Card border title={title} floating>
      <Table
        footer={() => (
          <Button icon="plus" onClick={() => setShowAddModal(true)}>
            {`Add ${termNameSingular}`}
          </Button>
        )}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Actions',
            key: 'actions',
            render: term => (
              <TaxonomyActions
                onEdit={({ termId }) => {
                  const term = data?.taxonomy.terms.find(t => t._id === termId);
                  setEditingTerm(term);
                  setShowEditModal(true);
                }}
                termNamePlural={termNamePlural}
                taxonomyId={data?.taxonomy?._id}
                termId={term?._id}
              />
            ),
          },
        ]}
        loading={loading}
        dataSource={terms}
      />
      <Modal
        title={`Edit ${termNameSingular}`}
        visible={showEditModal}
        destroyOnClose
        onOk={() => setShowAddModal(false)}
        onCancel={() => setShowEditModal(false)}
        footer={null}
      >
        <EditTaxonomyForm
          taxonomyName={termNamePlural}
          singularName={termNameSingular}
          term={termEditing}
          taxonomyId={taxonomyId}
          mutation={EDIT_TERM}
          existingsTerms={terms.map(r => r.name)}
          refetchQueries={[
            {
              query: GET_TAXONOMY,
              variables: {
                name: termNamePlural,
              },
            },
          ]}
          onComplete={() => setShowEditModal(false)}
        />
      </Modal>
      <Modal
        title={`Add ${termNameSingular}`}
        visible={showAddModal}
        destroyOnClose
        onOk={() => setShowAddModal(false)}
        onCancel={() => setShowAddModal(false)}
        footer={null}
      >
        <NewTaxonomyForm
          taxonomyName={termNamePlural}
          singularName={termNameSingular}
          mutation={CREATE_TERM}
          existingsTerms={terms.map(r => r.name)}
          refetchQueries={[
            {
              query: GET_TAXONOMY,
              variables: {
                name: termNamePlural,
              },
            },
          ]}
          onComplete={() => setShowAddModal(false)}
        />
      </Modal>
    </Card>
  );
};

export default Taxnomoy;
