import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import Button from '../Button';

const StyledDatePicker = styled(DatePicker)``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

class CustomWeekPicker extends React.Component {
  state = {
    endDate: null,
  };

  // eslint-disable-next-line react/sort-comp
  handleChangeOfWeekDate = momentDate => {
    const { onDateChange } = this.props;
    const endDate = momentDate.endOf('isoWeek');
    if (onDateChange) {
      onDateChange(endDate.format('YYYYMMDD'));
    }
  };

  // disable arrow keys from changing weeks.
  // keyHandling = ({ keyCode }) => {
  //   const endDate = moment(this.props.endDate);
  //   if (keyCode === 39) {
  //     this.goToNextWeek(endDate);
  //   }
  //   if (keyCode === 37) {
  //     this.goToPrevWeek(endDate);
  //   }
  //   if (keyCode === 40) {
  //     this.goToCurrentWeek();
  //   }
  // };

  // componentDidMount() {
  //   window.addEventListener('keyup', this.keyHandling);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('keyup', this.keyHandling);
  // }

  goToNextWeek = oldEndDate => {
    const endDate = moment(oldEndDate).add(1, 'week');
    this.props.onDateChange(endDate.toDate());
  };

  goToPrevWeek = oldEndDate => {
    const endDate = moment(oldEndDate).subtract(1, 'week');
    this.props.onDateChange(endDate.toDate());
  };

  goToCurrentWeek = () => {
    const endDate = moment().endOf('isoWeek');
    this.props.onDateChange(endDate.toDate());
  };

  render() {
    const { loading = false } = this.props;
    const endDate = moment(this.props.endDate);
    return (
      <Wrapper>
        <Button disabled={loading} icon="left" onClick={() => this.goToPrevWeek(endDate)} />
        <Button disabled={loading} onClick={() => this.goToCurrentWeek(endDate)}>
          Current Week
        </Button>
        <Button disabled={loading} icon="right" onClick={() => this.goToNextWeek(endDate)} />
        <StyledDatePicker
          disabled={loading}
          allowClear={false}
          format="MM/DD/YYYY"
          value={endDate}
          onChange={this.handleChangeOfWeekDate}
          placeholder="Select Week"
        />
      </Wrapper>
    );
  }
}

CustomWeekPicker.propTypes = {
  endDate: PropTypes.string,
  goToNextWeek: PropTypes.func,
  goToPrevWeek: PropTypes.func,
  goToCurrentWeek: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
};

export default CustomWeekPicker;
