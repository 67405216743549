import React, { getGlobal } from 'reactn';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import TimesheetContainer from './TimesheetContainer';
import TimesheetUploadsContainer from './TimesheetUploadsContainer';
import { Query } from 'react-apollo';
import { GET_USER_TIMESHEET } from '../queries';

const Message = styled.div`
  color: red;
  font-size: 1.2em;
  margin-bottom: 1em;
`;

class TimesheetCard extends React.Component {
  changeUrl = endDate => this.props.history.push(`/user/timesheets/${endDate}`);

  componentDidMount() {
    const paramsEndDate = get(this.props, 'match.params.endDate');
    // if url endDate is not specified in URL, then redirect to current weeks endDate
    if (paramsEndDate === 'create' || !paramsEndDate) {
      this.props.history.replace(`/user/timesheets/${this.props.endDate}`);
    } else if (this.props.endDate !== paramsEndDate) {
      // if url endDate does not match to redux state endDate, update state to match URL endDate
      const currentEndDate = moment(paramsEndDate).endOf('isoWeek');
      this.props.changeTimesheetDate(currentEndDate.format('YYYYMMDD'));
      this.props.history.replace(`/user/timesheets/${currentEndDate.format('YYYYMMDD')}`);
    }
  }

  render() {
    const showAttachments = this.global?.config['timesheet-attachments'] === '1';
    const global = getGlobal();

    return (
      <Query query={GET_USER_TIMESHEET} variables={{ endDate: this.props.endDate, user: null }}>
        {({ loading, error, data }) => {
          if (error) {
            window.location.reload();
          }
          return (
            <>
              {global?.config['company-short-name'] === 'Spruce' && (
                <Message>
                  <strong>ATTENTION:</strong> There is a delay in the implementation of NetSuite.
                  Please continue to use the Spruce Timesheet Portal (portal.sprucetech.com) to
                  record your time until further notice.
                </Message>
              )}
              <Card
                border
                title="Weekly Timesheet"
                padded={false}
                floating
                loading={data?._id || loading}
                withShadow
                actionComponent={
                  <WeekPicker
                    endDate={this.props.endDate}
                    onDateChange={endDate => {
                      const endDateString = moment(endDate).format('YYYYMMDD');
                      this.changeUrl(endDateString);
                      this.props.changeTimesheetDate(endDateString);
                    }}
                  />
                }
                style={{ minWidth: '1000px' }}
              >
                <TimesheetContainer timesheet={data?.timesheet} endDate={this.props.endDate} />
              </Card>
              {showAttachments && !loading && data.timesheet && (
                <Card title="Attachments" border padded={false} floating withShadow>
                  <TimesheetUploadsContainer
                    readonly={['Submitted-Pending', 'Approved'].includes(data?.timesheet.status)}
                    timesheet={data?.timesheet}
                    endDate={this.props.endDate}
                  />
                </Card>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

export default TimesheetCard;
