import * as Sentry from '@sentry/browser';

/* eslint-disable */
if (window.location.origin.includes('sprucetech.com')) {
  Sentry.init({
    dsn: 'https://0bcd563b31c44b87b1047e1f20ac304a@sentry.io/1297042',
    environment: window.location.origin.includes('portal.sprucetech.com')
      ? 'production'
      : 'development',
    attachStacktrace: true,
  });
}
