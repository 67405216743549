import React, { Fragment } from 'react';
import TimesheetApprovalListContainer from './TimesheetApprovalListContainer';
import TimesheetApprovedListContainer from './TimesheetApprovedListContainer';

const TimesheetApprovalListCard = () => (
  <Fragment>
    <TimesheetApprovalListContainer />
    <TimesheetApprovedListContainer />
  </Fragment>
);

export default TimesheetApprovalListCard;
