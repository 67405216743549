import React from 'react';
import { getGlobal } from 'reactn';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const { user } = getGlobal();
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
    if (user) {
      const { emails, firstName, lastName } = user;
      Sentry.configureScope(scope => {
        scope.setUser({
          email: emails[0].address,
          username: `${firstName} ${lastName}`,
        });
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setUser(null);
      });
    }
    Sentry.captureException(error, { extra: errorInfo });
    if (!window.location.href.includes('localhost')) {
      window.location.href = '/';
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      // don't show big error message to user, send user to main page
      // come up with better way to show errors to user
      // return null;
      console.error(error);
      return (
        <div style={{ padding: '3rem' }}>
          <h2>
            <span>
              Error has been captured and support team has been notified. If issue persists,
              contact&nbsp;
            </span>
            {/* <a
              href={`mailto:portal-support@sprucetech.com?subject=Portal Error: ${
                error.message
              }&body=${encodeURIComponent(
                `${user.firstName} ${user.lastName} \n\n` + error.stack,
              )}`}
            >
              portal-support@sprucetech.com
            </a> */}
            <span> with additional information.</span>
          </h2>
          <h3>{error.message}</h3>
          <code>{error.stack}</code>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
