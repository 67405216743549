import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import Cascader from '../../common/components/Cascader';
import { GET_USER_TIMESHEET, GET_TIMESHEETS_FOR_USER } from '../queries';
import { ADD_TIMESHEET_LINEITEM, PREFILL_ADD_TIMESHEET_LINEITEM } from '../mutations';
import moment from 'moment';
import client from '../../../api/graphql/client';
import { Query } from '@apollo/react-components';
import { showError } from '../../common/notifications';
import { useLocation } from 'react-router-dom';
import useLocalStorage from '../../common/hooks/useLocalStorage';
import TaskSelector from '../../common/components/TaskSelector';

const Col = styled.td`
  padding: 1em;
  font-weight: 600;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  &:first-child {
    padding-left: 0;
  }
`;

const Hours = styled.span`
  ${props => props.highlightColor && `color: ${props.highlightColor}`};
  font-weight: bold;
`;

const Wrapper = styled.tr`
  border-top: 1px solid #f1f1f1;
`;

export const constructOptions = (projects, selectedDeliverableIds) =>
  projects.map(project => {
    const milestones = project.deliverables
      .reduce((acc, cur) => {
        const milestone = acc.find(milestone => milestone.label === cur.milestoneName);
        if (milestone) {
          if (!milestone.children) {
            milestone.children = [
              {
                label: cur.deliverableName,
                value: cur._id,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              },
            ];
          } else {
            const existingDeliverable = milestone.children.find(
              child => child.label === cur.deliverableName,
            );
            if (!existingDeliverable) {
              milestone.children.push({
                label: cur.deliverableName,
                value: cur._id,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              });
            }
          }
          return acc;
        }

        return [
          ...acc,
          {
            label: cur.milestoneName,
            value: cur.milestoneName,
            children: [
              {
                value: cur._id,
                label: cur.deliverableName,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              },
            ],
          },
        ];
      }, [])
      .map(milestone => ({
        ...milestone,
        children: milestone.children.filter(d => d.label !== milestone.label),
      }));

    const label = project.client?.shortName
      ? `${project.client.shortName} / ${project.name}`
      : project.name;

    return {
      label,
      value: project._id,
      children: milestones,
    };
  });

const TimesheetGridRowAddNew = ({
  timesheetId,
  user,
  data,
  loading,
  dailyTotals,
  isLocked,
  endDate,
  lineItems,
  selectedDeliverableIds,
  onSubmit = () => {},
  onSubmitEnd = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = useLocation();
  const [useNewTaskSelector] = useLocalStorage('useNewTaskSelector', false);

  const handleClick = async item => {
    console.log({ item });
    const deliverableId = item[item.length - 1];
    const projectId = item[0];
    setIsSubmitting(true);
    await client.mutate({
      mutation: ADD_TIMESHEET_LINEITEM,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_TIMESHEET,
          variables: {
            endDate: endDate,
            user: user || undefined,
          },
        },
      ],
      variables: {
        deliverableId,
        timesheetId,
        projectId,
        user: user || undefined,
      },
    });
    setIsSubmitting(false);
  };
  const handlePrefill = data => {
    const { timesheets } = data?.me;
    const filterTimesheet = timesheets.filter(
      item => item.status === 'Submitted-Pending' || item.status === 'Approved',
    );
    if (filterTimesheet?.length) {
      const { lineItems } = filterTimesheet[0];
      const groupedLineItems = lineItems.map(element => {
        return JSON.stringify({
          deliverableId: element.deliverableId,
          projectId: element.projectId,
        });
      });
      setIsSubmitting(true);
      client.mutate({
        mutation: PREFILL_ADD_TIMESHEET_LINEITEM,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate: endDate,
              user: user || null,
            },
          },
        ],
        variables: {
          groupedLineItems,
          timesheetId,
        },
      });
      setIsSubmitting(false);
    } else {
      showError({ message: 'No timesheet submitted in last few months' });
    }
  };

  const renderTotalForWeekDay = hours => {
    let highlightColor;
    if (hours > 8) {
      highlightColor = 'orange';
    }
    if (hours > 12) {
      highlightColor = 'red';
    }
    return (
      <Col align="center">
        <Hours highlightColor={highlightColor}>{hours === 0 ? '' : hours}</Hours>
      </Col>
    );
  };

  const options =
    data && data.projects ? constructOptions(data.projects, selectedDeliverableIds) : [];

  return (
    <Wrapper>
      {isLocked ? (
        <Col />
      ) : (
        <Col>
          {options.length === 0 && !loading ? (
            <Tooltip trigger="hover" placement="right" title="You are not part of any project">
              <Button disabled icon="plus">
                Select Task
              </Button>
            </Tooltip>
          ) : (
            <>
              {useNewTaskSelector ? (
                <TaskSelector
                  options={options}
                  onSelect={selected => handleClick([selected.project, undefined, selected.task])}
                />
              ) : (
                <Cascader
                  notFoundContent="No results"
                  expandTrigger="click"
                  options={options}
                  onChange={handleClick}
                  popupPlacement="topLeft"
                  value={null}
                  size="large"
                  placeholder="Search for task"
                  showSearch={{
                    matchInputWidth: false,
                    filter: (inputValue, path) => {
                      return path.some(option => {
                        if (option.label) {
                          return (
                            option.label.toLowerCase().indexOf(inputValue?.toLowerCase() || '') > -1
                          );
                        }
                        return null;
                      });
                    },
                  }}
                  onPopupVisibleChange={open => {
                    setIsOpen(open);
                  }}
                >
                  {!isOpen && (
                    <Button
                      ghost
                      type="primary"
                      size="large"
                      id="selectDeliverableBtn"
                      loading={loading || data.loading || isSubmitting}
                      icon="plus"
                    >
                      Select Task
                    </Button>
                  )}
                </Cascader>
              )}

              {!lineItems && !pathname.includes('manualTimesheets') && (
                <Query
                  query={GET_TIMESHEETS_FOR_USER}
                  variables={{ startDate: moment().add(-60, 'days').format('YYYYMMDD'), endDate }}
                >
                  {({ loading, error, data }) => {
                    if (error) throw new Error(error);
                    return (
                      <Tooltip
                        placement="top"
                        title="Prefills timesheet with tasks added in last successfully submitted timesheet"
                      >
                        <Button
                          disabled={loading}
                          style={{ marginLeft: '5px' }}
                          type="link"
                          id="prefill"
                          onClick={() => {
                            handlePrefill(data);
                          }}
                          icon="copy"
                        >
                          Prefill Timesheet
                        </Button>
                      </Tooltip>
                    );
                  }}
                </Query>
              )}
            </>
          )}
        </Col>
      )}
      <Col align="center" />
      {renderTotalForWeekDay(dailyTotals.mon)}
      {renderTotalForWeekDay(dailyTotals.tue)}
      {renderTotalForWeekDay(dailyTotals.wed)}
      {renderTotalForWeekDay(dailyTotals.thu)}
      {renderTotalForWeekDay(dailyTotals.fri)}
      {renderTotalForWeekDay(dailyTotals.sat)}
      {renderTotalForWeekDay(dailyTotals.sun)}
    </Wrapper>
  );
};

export default TimesheetGridRowAddNew;
