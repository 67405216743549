import gql from 'graphql-tag';

export const GET_AVAILABLE_DELIVERABLES = gql`
  query getAvailableDeliverables($user: String, $endDate: String) {
    projects(user: $user, endDate: $endDate) {
      _id
      name
      globallyVisible
      client {
        _id
        name
        shortName
      }
      deliverables {
        _id
        deliverableName
        milestoneName
        disabled
        includeInAllProjects
      }
    }
  }
`;

export const Timesheet = {
  fragments: {
    weekday: gql`
      fragment Weekday on Task {
        _id
        date
        hours
        comment
      }
    `,
  },
};

export const GET_USER_TIMESHEET = gql`
  query getUserTimesheet($endDate: String, $user: String) {
    timesheet(endDate: $endDate, user: $user) {
      _id
      status
      totalHours
      startDate
      endDate
      isLocked
      documentUrl
      timesheetAttachments {
        _id
        url
        description
      }
      timesheetExpense {
        description
        amount
        attachments {
          _id
          url
        }
      }
      timeline {
        date
        createdBy
        metadata
        type
      }
      approvalsRequired {
        status
        approvers {
          firstName
          lastName
          imageUrl
          emails {
            address
          }
        }
      }
      lineItems {
        project {
          _id
          name
          client {
            _id
            shortName
          }
        }
        deliverable {
          _id
          deliverableName
          milestoneName
          disabled
          ignoreHoursInTotal
        }
        comment
        status
        monTask {
          ...Weekday
        }
        tueTask {
          ...Weekday
        }
        wedTask {
          ...Weekday
        }
        thuTask {
          ...Weekday
        }
        friTask {
          ...Weekday
        }
        satTask {
          ...Weekday
        }
        sunTask {
          ...Weekday
        }
      }
    }
  }
  ${Timesheet.fragments.weekday}
`;

export const GET_TIMESHEETS_FOR_USER = gql`
  query getTimesheetsForUser($startDate: String, $endDate: String) {
    me {
      _id
      timesheets(startDate: $startDate, endDate: $endDate) {
        approvalsRequired {
          _id
          status
          approvedOn
          approvedBy
          canApprove
          approvers {
            _id
            firstName
            lastName
            imageUrl
            emails {
              address
            }
          }
        }
        lineItems{
          deliverableId
          projectId
        }
        user {
          _id
          firstName
          lastName
        }
        status
        totalHours
        endDate
        userId
        _id
        documentUrl
      }
    }
  }
`;
