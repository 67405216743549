/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { keyframes, createGlobalStyle } from 'styled-components';

export const fadeIn = keyframes`
  // 0% {
  //   opacity: 0;
  // }
  // 100% {
  //   opacity: .6;
  // }
`;

export const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
body {
  background: none;
}
.ant-cascader-menu-item {
    min-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.ant-table-row-cell-break-word {
  word-break: unset !important;
}
`;
