import { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';
import { showError } from '../../common/notifications';
import { GET_DELIVERABLES_BY_PROJECT_ID } from '../queries';
import UpdateDeliverableForm from '../../common/components/UpdateDeliverableForm';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const REMOVE_PROJECT_DELIVERABLE = gql`
  mutation removeDeliverable($deliverableId: ID!) {
    removeDeliverable(deliverableId: $deliverableId)
  }
`;

const ProjectDetailsDeliverableActions = ({ deliverable, milestoneNames, refetchQueries }) => {
  const [updateDeliverableVisible, setUpdateDeliverableVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [removeDeliverable] = useMutation(REMOVE_PROJECT_DELIVERABLE);
  const {
    projectId,
    _id: deliverableId,
    key,
    milestoneName,
    children = [],
    milestoneBudget,
  } = deliverable;

  const isMilestone = key === milestoneName;
  const childrenHaveBudget = children?.some(c => c.budget);

  return (
    <ActionsWrapper>
      <div className="flex flex-start">
        {!childrenHaveBudget && (
          <Button className="mr-2" onClick={() => setUpdateDeliverableVisible(true)}>
            Edit
          </Button>
        )}
        {(!isMilestone || !children) && (
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this deliverable?"
            disabled={deliverable.totalApprovedHours > 0}
            onConfirm={async () => {
              setIsRemoving(true);
              try {
                const res = await removeDeliverable({
                  variables: { deliverableId },
                  refetchQueries: [
                    {
                      query: GET_DELIVERABLES_BY_PROJECT_ID,
                      variables: {
                        projectId,
                      },
                    },
                  ],
                  awaitRefetchQueries: true,
                });
                if (res.errors) {
                  setIsRemoving(false);
                  const message = res?.errors[0]?.message;
                  showError({ message });
                }
              } catch (error) {
                console.error({ error });
                setIsRemoving(false);
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip
              title={
                deliverable.totalApprovedHours > 0 &&
                'Cannot delete deliverable. Approved hours have already been submitted to it.'
              }
            >
              <Button
                disabled={deliverable.totalApprovedHours > 0}
                type="danger"
                loading={isRemoving}
                icon="close"
              ></Button>
            </Tooltip>
          </Popconfirm>
        )}
      </div>
      <Modal
        title="Update Deliverable"
        visible={updateDeliverableVisible}
        destroyOnClose
        onOk={() => setUpdateDeliverableVisible(false)}
        onCancel={() => setUpdateDeliverableVisible(false)}
        footer={null}
      >
        <UpdateDeliverableForm
          isMilestone={isMilestone}
          milestoneBudget={milestoneBudget}
          deliverable={deliverable}
          refetchQueries={refetchQueries}
          onComplete={() => setUpdateDeliverableVisible(false)}
          milestoneNames={milestoneNames}
        />
      </Modal>
    </ActionsWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.meteor?.user,
});

export default connect(mapStateToProps)(ProjectDetailsDeliverableActions);
