import gql from 'graphql-tag';

export const GET_TEMPLATES = gql`
  query getTemplates {
    templates {
      _id
      name
    }
  }
`;

export const GET_CLIENTS = gql`
  query getClients {
    admin {
      clients {
        _id
        name
        shortName
      }
    }
  }
`;

export const ADMIN_GET_USER_BY_ID = gql`
  query getUserByIdAsAdmin($userId: String!) {
    admin {
      user(userId: $userId) {
        _id
        firstName
        lastName
        payrollId
        accountActive
        emails {
          address
        }
        projects {
          _id
          name
          projectManagers {
            _id
            name
          }
        }
        approvers {
          type
          value
        }
        delegatedApprovers {
          type
          value
        }
        imageUrl
        title
        role
        groups
      }
    }
  }
`;

export const ADMIN_GET_USERS = gql`
  query getUsersAsAdmin($manualUser: Boolean) {
    admin {
      users(manualUser: $manualUser) {
        _id
        firstName
        lastName
        lastActiveOn
        payrollId
        role
        accountActive
        employeeType
        emails {
          address
          verified
        }
        approvers {
          type
          value
        }
        delegatedApprovers {
          type
          value
        }
      }
    }
  }
`;

export const GET_TIMESHEET_THRESHOLD = gql`
  query getTimesheetThreshold {
    timesheetThreshold
  }
`;
export const GET_WEEKLY_REMINDER_EMAIL = gql`
  query getTimesheetThreshold {
    weeklyReminderEmailJob
  }
`;

export const EMAIL_STATS_RECEIVERS = gql`
  query emailStatsReceivers {
    admin {
      emailStatsRecievers
      users {
        _id
        firstName
        lastName
        lastActiveOn
        payrollId
        accountActive
        emails {
          address
          verified
        }
        approvers {
          type
          value
        }
        delegatedApprovers {
          type
          value
        }
      }
    }
  }
`;

export const UPDATE_SNAPSHOTS = gql`
mutation updateSnapshots($limit: Int, $timesheetId: ID, $projectId: ID) {
  updateTimesheetSnapshots(limit: $limit, timesheetId: $timesheetId, projectId: $projectId)
}
`;
