import classNames from 'classnames';
import { trpc } from '../../../utils/trpc';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import moment from 'moment';
import { useState } from 'react';

const TimesheetsPage = () => {
  const [endDate, setEndDate] = useState('20220828');
  const timesheetQuery = trpc.useQuery(['timesheets.byEndDate', { endDate }], {
    refetchOnWindowFocus: false,
  });

  return (
    // @ts-ignore
    <Card
      floating
      bodyBackground={null}
      title="Timesheets"
      loading={timesheetQuery.isLoading}
      actionComponent={
        <WeekPicker
          endDate={endDate}
          onDateChange={(newEndDate: moment.MomentInput) =>
            setEndDate(moment(newEndDate).format('YYYYMMDD'))
          }
        />
      }
    >
      <div className="flex flex-wrap">
        {timesheetQuery.data?.sort((a, b) => a.status.localeCompare(b.status)).map(ts => (
          <Link
            key={ts.id}
            to={`/reports/timesheets/${ts.id}`}
            target="_blank"
            className={classNames('w-5 h-5 bg-white p-2 m-1', {
              'bg-green-100': ts.status === 'Approved',
              'bg-orange': ts.status === 'Submitted-Pending',
              'bg-red-100': ts.status === 'Declined',
            })}
          />
        ))}
      </div>
    </Card>
  );
};

export default TimesheetsPage;
